import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardLink,
  MDBCardSubTitle,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

const Projects = () => {

  return (
    <section id="projects" className="text-center">
      <h3 className="my-5" style={{ color: "#3d1f00" }}>
        <strong>Projects</strong>
      </h3>
      <MDBRow className="row-cols-1 row-cols-md-3 g-4">
        <MDBCol>
          <MDBCard style = {{borderColor: "#654321", backgroundColor: "#f0d9b5"}} className="mb-3" height="100%">
            <MDBCardBody>
              <MDBCardTitle style={{ color: "#3d1f00" }}>This Portfolio Site</MDBCardTitle>
              <MDBCardSubTitle className="mb-2 text-muted">MDB5 React UI Kit, React, GitHub Codespaces
              </MDBCardSubTitle>
              <MDBCardText style={{ color: "#3d1f00" }}><p>
                  It's frontend is built using the Codespaces feature of GitHub.
                </p></MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol>
          <MDBCard style = {{borderColor: "#654321", backgroundColor: "#f0d9b5"}} className="mb-3" height="100%">
            <MDBCardBody>
              <MDBCardTitle style={{ color: "#3d1f00" }}>
              PocketStock</MDBCardTitle>
              <MDBCardSubTitle className="mb-2 text-muted">
                React, Node.js, Express, MongoDB, Netlify, Heroku, MongoDB Atlas
              </MDBCardSubTitle>
              <MDBCardText style={{ color: "#3d1f00" }}>
                <p>
                  This is our final project for the Full Stack Web Development
                  Bootcamp at Northcoders. It was put together by 4 students in
                  10 days.{" "}
                </p>
              </MDBCardText>
              <MDBCardLink href="https://northcoders.com/projects/oct-2022/pocket-stock" style={{ color: "#654321" }}>
                See the presentation!
              </MDBCardLink>
              <MDBCardLink href="https://pocket-stock-by-team3.netlify.app/ " style={{ color: "#654321" }}>
                See the live site!
              </MDBCardLink>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol>
          <MDBCard style = {{borderColor: "#654321", backgroundColor: "#f0d9b5"}} className="mb-3" height="100%"> 
            <MDBCardBody>
              <MDBCardTitle style={{ color: "#3d1f00" }}>
              Northcoders News</MDBCardTitle>
              <MDBCardSubTitle className="mb-2 text-muted">
                React, Node.js, Express, PostgreSQL, Heroku
              </MDBCardSubTitle>
              <MDBCardText style={{ color: "#3d1f00" }}>
                <p>
                  Built as one of the solo projects for the Full Stack Web
                  Development Bootcamp at Northcoders. NC News is an example of
                  a simple news aggregation site, where users can post comments, and vote on articles and comments.
                  I've continued to work on this project after the course ended, adding new features and improving the UI.
                </p>
              </MDBCardText>
              <MDBCardLink href="https://colemdevs-northcoders-news.netlify.app/" style={{ color: "#654321" }}
              >See the live site!</MDBCardLink>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </section>
  );
};

export default Projects;
