//Welcome Page for Portfolio Website

import React from "react";
import { MDBBtn } from "mdb-react-ui-kit";

const LandingPage = () => {

  
  return (
    <header>
      <div
        id="intro-example"
        className="p-5 text-center bg-image shadow-2-strong"
        style={{
          backgroundImage:
            "url('https://mdbootstrap.com/img/new/slides/041.webp')",
          height: "100vh",
        }}
      >
        <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-white">
              <h1 className="mb-3 mx-5" style={{ color: "#f0d9b5" }}>
                Michael Cole
              </h1>
              <h4 className="mb-3 mx-5" style={{ color: "#f0d9b5" }}
                >Full Stack Engineer</h4>
              <MDBBtn className="m-1" tag="a" outline size="lg" href="/about" style={{ borderColor: "#f0d9b5", color: "#f0d9b5" }}>
                About Me
              </MDBBtn>
              <MDBBtn
                className="m-1"
                tag="a"
                outline
                size="lg"
                href="/projects"
                style={{ borderColor: "#f0d9b5", color: "#f0d9b5" }}
              >
                Projects
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default LandingPage;
