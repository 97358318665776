import React from "react";
import { MDBFooter } from "mdb-react-ui-kit";

const Footer = () => {


  return (
    <MDBFooter className="text-center">
      <div
        className="text-center p-3"
        style={{ backgroundColor: "#f0d9b5" }}
      >
        <p style={{ color: "#3d1f00" }}>
        &copy; {new Date().getFullYear()} Copyright: michaelcole.dev
        </p>
        <p className="mb-3" style={{ color: "#3d1f00" }}
        >powered by MDB5, React, and a coffee.</p>
      </div>
    </MDBFooter>
  );
};

export default Footer;
