import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBContainer,
} from "mdb-react-ui-kit";
const Avatar = require("../assets/Avatar.png");

const About = () => {


  return (
    <section className="text-center my-5" id="about">
      <h3 className="my-5" style={{ color: "#3d1f00" }}>
        <strong>About Me</strong>
      </h3>
      <MDBRow>
        {/* Left side of the page */}
        <MDBCol className="md-6">
          <img
            src={Avatar}
            className="rounded-circle shadow-1-strong mb-4"
            alt="Avatar"
          />
          <p style={{ color: "#3d1f00" }}>
            A recent graduate from Northcoders who has a passion for solving
            life’s issues be that a need or a want, through code. I like that
            coding has clear structures and routines, that fit well with my
            ADHD. Coming from a background working in customer service, where I
            enjoyed the rewarding feeling being able to reassure people and
            solve the problems that they were having, I am now seeking an
            opportunity with a business which will support my passion for
            continuous learning and development throughout my career.
          </p>
       
        </MDBCol>
        <MDBCol className=" md-6">
          <MDBContainer px-4-lg-5>
            {/* Right Side of About Page */}
            <h2 style = {{color: "#3d1f00"}}>
              <strong>Technologies I have worked with</strong>
            </h2>
            <MDBRow className="gx-4 gx-lg-5">
              <MDBCol className="lg-3 md-6 text-center">
                <div className="mt-5">
                  <div className="mb-2">
                    <MDBIcon far icon="gem" size="3x"
                 />
                  </div>
                  <h3 className="h4 mb-2">Languages</h3>
                  <p className="text-muted mb-0">
                    <i className="fab fa-js"></i> JavaScript
                    <br />
                    <i className="fab fa-html5"></i> HTML5
                    <br />
                    <i className="fab fa-css3-alt"></i> CSS3
                    <br />
                    <i className="fab fa-SQL"></i> SQL
                    <br />
                  </p>
                </div>
              </MDBCol>
              <MDBCol className="lg-3 md-6 text-center">
                <div className="mt-5">
                  <div className="mb-2">
                    <MDBIcon fas icon="laptop" size="3x" />
                  </div>
                  <h3 className="h4 mb-2">Frameworks & Libraries</h3>
                  <p className="text-muted mb-0">
                    <i className="fab fa-react"></i> React
                    <br />
                    <i className="fab fa-node"></i> Node
                    <br />
                    <i className="fab fa-express"></i> Express
                    <br />
                    <i className="fab fa-postgresql"></i> PostgreSQL
                    <br />
                    <i className="fab fa-jest"></i> Jest
                    <br />
                  </p>
                </div>
              </MDBCol>
              <MDBCol className="lg-3 md-6 text-center">
                <div className="mt-5">
                  <div className="mb-2">
                    <MDBIcon fas icon="globe-americas" size="3x" />
                  </div>
                  <h3 className="h4 mb-2">Learning Path</h3>
                  <p className="text-muted mb-0">
                    <i className="fab fa-mdb"></i> MDB5 React UI Kit
                    <br />
                  </p>
                  <p className="text-muted mb-0"> MongoDB</p>
                  <p className="text-muted mb-0">Heroku</p>
                  <p className="text-muted mb-0">Netlify</p>
                  <p className="text-muted mb-0">ElephantSQL</p>
                  <p className="text-muted mb-0">Codespaces</p>
                  <p className="text-muted mb-0">Microsoft Learn Python</p>
                  <p className="text-muted mb-0">Microsoft Learn Typescript</p>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
    </section>
  );
};

export default About;
