import React, { useState } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
} from "mdb-react-ui-kit";
const Resume = require("../assets/Michael_Cole_Resume_04-2023.pdf");


const Navbar = () => {
  const [showBasic, setShowBasic] = useState(false);


  
  return (
    <MDBNavbar expand="lg" style={{ backgroundColor: "#f0d9b5" }} sticky>
      <MDBContainer fluid>
        <MDBNavbarBrand href="/" style={{ color: "#3d1f00" }}>
          <h1> <strong>
            Michael Cole
          </strong>
          </h1>
        </MDBNavbarBrand>

        <MDBNavbarToggler
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowBasic(!showBasic)}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar show={showBasic}>
          {/* Left links */}
          <MDBNavbarNav className="me-auto mb-2 mb-lg-0">
            <MDBNavbarItem>
              <MDBNavbarLink active aria-current="page" href="/" style={{ color: "#3d1f00" }}>
                Home
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href="/about"style={{ color: "#3d1f00" }}>
                About
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href="/projects"style={{ color: "#3d1f00" }}>
                Projects
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
          <MDBNavbarNav className="mb-2 mb-lg-0 d-flex justify-content-end">
            <MDBNavbarItem>
              {/* Resume */}
              <MDBNavbarLink href={Resume} download style={{ color:  "#654321" }}>
              <MDBIcon
                icon="fa-solid fa-file-download"
                size="lg"
                color="none"
                style={{ color: "#654321" }}
              />
              <strong>Download Resume</strong>
          </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href="mailto: contactcolem@gmail.com">
                <MDBIcon
                  icon="fa-solid fa-envelope"
                  size="lg"
                  color="none"
                  style={{ color: "#654321" }}
                />
              </MDBNavbarLink>
            </MDBNavbarItem>
            {/* Linkedin  */}
            <MDBNavbarItem>
              <MDBNavbarLink href="https://www.linkedin.com/in/colemdev/">
                <MDBIcon
                  fab
                  icon="fa-brands fa-linkedin"
                  size="lg"
                  color="none"
                  style={{ color: "#654321" }}
                />
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              {/* Github */}
              <MDBNavbarLink href="https://www.github.com/colemdev/">
                <MDBIcon
                  fab
                  icon="fa-brands fa-github-square"
                  size="lg"
                  color="none"
                  style={{ color: "#654321" }}
                />
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
};

export default Navbar;
